import styled from "styled-components"
import Container from "@mui/material/Container"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Section = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.primary.light};
  padding: 0;
  margin-top: 80px;
  margin-bottom: 80px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-top: 40px;
    margin-bottom: 40px;
  }
`
export const Wrapper = styled(Container)`
  max-width: 780px;
  padding-top: 0;
  padding-bottom: 0;
  h1,
  h2,
  h3,
  h4 {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: normal;
    margin-top: 0;
    font-size: ${({ theme }) => theme.typography.pxToRem(30)};
    line-height: 1.33em;
    margin-bottom: 2rem;
    ${({ theme }) => theme.breakpoints.down("md")} {
      font-size: ${({ theme }) => theme.typography.pxToRem(24)};
      margin-bottom: 1rem;
    }
  }

  p {
    font-size: ${({ theme }) => theme.typography.pxToRem(21)};
    line-height: 1.52em;
    color: ${({ theme }) => theme.palette.text.secondary};
    font-weight: normal;
    margin-bottom: 1rem;
    margin-top: 0;
  }

  h1 {
    margin-top: 0;
    font-size: ${({ theme }) => theme.typography.pxToRem(30)};
    line-height: 1.33em;
    margin-bottom: 2rem;
    ${({ theme }) => theme.breakpoints.down("md")} {
      font-size: ${({ theme }) => theme.typography.pxToRem(24)};
      margin-bottom: 1rem;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
  a {
    color: #2944e6;
  }
`
